<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card" style="max-width: 400px">
      <header class="modal-card-head">
        <b-icon icon="mdi mdi-exclamation-thick" size="is-large" type="is-warning"> </b-icon>
        <p class="modal-card-title">Error Logging In</p>
        <button type="button" class="delete" @click="close" />
      </header>
      <section class="modal-card-body">
        <p>
          <b>{{ message }}</b>
        </p>
        <p>Please try again.</p>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" type="button" @click="close">OK</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    isActive: {
      type: Boolean,
      require: true,
      default: false
    },
    message: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      isModalActive: false
    };
  },
  watch: {
    isActive(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.close();
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.modal-card-title {
  font-size: 2rem;
  text-align: center;
}
</style>
